import providerList from "./provider-list/ProviderList.vue";
import providerService from "../../services/provider/ProviderService";
import i18n from "../../i18n/index";

export default {
  components: {
    providerList
  },
  data: () => ({
    dialog: false,
    hideList: false,
    status: null,
    name: "",
    url: "",
    nit: "",
    id: null,
    post: true
  }),
  mounted() {},
  methods: {
    handleProvider(item) {
      this.post = false;
      console.log("id", item);
      this.id = item.id;
      this.hideList = true;
      this.name = item.name;
      this.url = item.url;
      this.nit = item.nit;
    },
    addProvider(post) {
      this.hideList = true;
      this.post = post
    },
    closeProvider() {
      this.hideList = false;
      this.dialog = false;
    },
    createProvider(id) {
      const items = {
        name: this.name,
        url: this.url,
        nit: this.nit,
        id: this.id,
        status: this.status? 1 : 0
      };
      providerService.createProvider(this.post, items, id).then(value => {
        if (value) {
          this.$notify({
            group: "app",
            type: "success",
            text: i18n.t("providers.save-success")
          });
          this.hideList = !this.hideList;
          this.$refs.providerList.getProviders();
          (this.name = ""), (this.url = ""), this.nit;
        }
      });
    }
  }
};
