var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hideList,
              expression: "hideList",
            },
          ],
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "material-card",
                {
                  attrs: {
                    color: "primary",
                    title: _vm.$t("providers.create-provider"),
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: _vm.$t("providers.name") },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Nit" },
                            model: {
                              value: _vm.nit,
                              callback: function ($$v) {
                                _vm.nit = $$v
                              },
                              expression: "nit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "URL" },
                            model: {
                              value: _vm.url,
                              callback: function ($$v) {
                                _vm.url = $$v
                              },
                              expression: "url",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c("v-switch", {
                            attrs: { label: "Activar/desactivar" },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { round: "", color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.createProvider(_vm.id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save-button-label")))]
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { persistent: "", "max-width": "290" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "red",
                                          round: "",
                                          dark: "",
                                        },
                                      },
                                      on
                                    ),
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "headline" }, [
                                _vm._v(_vm._s(_vm.$t("providers.alert-close"))),
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        flat: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = false
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("no")))]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        flat: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeProvider()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("yes")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideList,
              expression: "!hideList",
            },
          ],
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "material-card",
                { attrs: { color: "primary", title: "Lista de prestadores" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProvider(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("providers.create-provider"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("providerList", {
                        ref: "providerList",
                        on: { "row-click": _vm.handleProvider },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }